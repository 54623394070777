
import { mapMutations, mapState } from "vuex";

  export default {
    name: "Header",
    components: {
      ButtonRegion : () => import("@/components/navigation/Header/ButtonRegion"),
    },
    methods: {
      openUserPopup() {
        if(process.client) {
          this.showModal({componentName:'UserModal'})
        }
      },
      ...mapMutations({
        showModal: 'modal/showModal',
      })
    },
    computed: {
      ...mapState('header', {
        slimHeader: state => state.slimHeader,
      }),
    }
  }
